import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
  data: {
    page: GatsbyTypes.WpPost
  }
  location?: any
  prefix?: string
}

export interface EducationContextProps {
  data?: GatsbyTypes.WpPost
}

export const PostContext = React.createContext({})

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { databaseId, flexContent, seo },
  },
  location = {},
  prefix = 'post_Flexcontent',
}) => {
  return (
    <PostContext.Provider value={{ id: databaseId }}>
      <Layout>
        <SEO seo={seo} />
        <FlexContentHandler
          prefix={prefix}
          fields={flexContent}
          location={location}
          data={databaseId}
        />
      </Layout>
    </PostContext.Provider>
  )
}

export const pageQuery = graphql`
  query postById($pageId: String!) {
    page: wpPost(id: { eq: $pageId }) {
      ...generalPostFragment
    }
  }
`

export default PageTemplate
